import React from "react";

// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/autoplay";
// import "swiper/css/scrollbar";

const Slider = ({ children }) => {
  const [actualSlide, setActualSlide] = React.useState(0);
  const arrayChildren = React.Children.toArray(children);

  return (
    <>
      <p>
        {actualSlide + 1}/{arrayChildren.length}{" "}
        {arrayChildren[actualSlide].props.alt}
      </p>

      <div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={16}
          slidesPerView={1}
          pagination={{ clickable: true }}
          speed={1000}
          onSlideChange={(e) => setActualSlide(e.realIndex)}
        >
          {arrayChildren.map((child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
